import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DeleteConfirmationComponent } from 'src/app/redeem/modal/delete-confirmation/delete-confirmation.component';
import { ApiService } from 'src/app/services/api-services/api.service';
import { SharedService } from 'src/app/services/shared.service';
import {
  showError,
  showSuccess,
  showWarning,
} from 'src/app/utility/common-logic';
import { AuthData } from 'src/app/utility/model/token-auth';
import { environment } from 'src/environments/environment';

enum SignE {
  minus,
  plus,
}

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {
  @Input() editable: boolean;
  @Input() cartDetails: any;
  @Input() cartItems: any;
  cartCount: number;
  imgUrl = environment.url.img;
  @Output() receivedCartChanges = new EventEmitter<any>();
  SignE = SignE;
  allowPayment = environment.settings.allowPayment;
  gateway: string | null;
  maxQty = environment.settings.rewards.maxQty;
  maxQtyCart = environment.settings.rewards.maxQtyCart;
  cartTotalQuantity: number = 0;
  authData: Partial<AuthData> = {};

  constructor(
    private shared: SharedService,
    private api: ApiService,
    private translate: TranslateService,
    public dialog: MatDialog,
    private toastr: ToastrService,
    private authDataStore: Store<{ authData: AuthData }>,
  ) {}

  ngOnInit(): void {
    this.shared.cartItems.subscribe((res) => (this.cartItems = res));
    this.gateway = this.allowPayment ? 'ntt' : null;
    this.cartTotalQuantity = this.cartItems?.reduce(
      (total, item) => total + item.quantity,
      0,
    );
    this.authDataStore
      .select('authData')
      .subscribe((res: Partial<AuthData>) => {
        this.authData = res;
      });
    if (
      this.authData.tokenAuth?.organization_settings
        ?.enable_multiple_redemptions == 1
    ) {
      this.maxQty = 20;
      this.maxQtyCart = 60;
    }
  }

  async getCartDetails() {
    this.shared.spinner = true;
    await this.api
      .getCartDetails()
      .then(async (res: any) => {
        this.cartDetails = res;
        this.cartCount = res?.count;
        this.cartTotalQuantity = res?.results.reduce(
          (total, item) => total + item.quantity,
          0,
        );
        this.shared.cartCount.next(res.count);
        this.shared.cartDetails.next(res);
        this.shared.cartItems.next(res?.results);
        this.shared.cartStatus.next(res?.status);
        this.receivedCartChanges.emit({
          cartDetails: this.cartDetails,
          cartItems: this.cartItems,
          count: res.count,
        });
      })
      .catch((err) => showError(err, this.toastr, this.translate))
      .finally(() => (this.shared.spinner = false));
  }

  onRemoveConfirm(item) {
    const dialogRef = this.dialog.open(DeleteConfirmationComponent, {
      data: {
        message: this.translate.instant('msg.item.delete'),
        buttonText: {
          ok: this.translate.instant('common.delete'),
          cancel: this.translate.instant('common.cancel'),
        },
      },
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.removeFromCart(item);
      }
    });
  }

  async removeFromCart(item) {
    this.shared.spinner = true;
    const updateDate = { quantity: 0, gateway: this.gateway };
    await this.api
      .updateCart(item.id, updateDate)
      .then((res) => {
        showSuccess(
          'cart.msgRemovedSuccess',
          this.toastr,
          this.translate,
          true,
        );
        const index = this.cartItems.findIndex((o) => o.id === item.id);
        this.cartItems.splice(index, 1);
        this.getCartDetails();
      })
      .catch((err) =>
        showError(
          'cart.msgRemovedFailed',
          this.toastr,
          this.translate,
          true,
          true,
        ),
      )
      .finally(() => (this.shared.spinner = false));
  }

  async changeQty(item: any, type: SignE) {
    if (
      (type === SignE.minus && item.quantity == 1) ||
      (type === SignE.plus &&
        (item.quantity == this.maxQty ||
          this.cartTotalQuantity == this.maxQtyCart))
    ) {
      const message =
        item.quantity == this.maxQty
          ? this.translate.instant('msg.qty.maxRewardQuantity', {
              qty: this.maxQty,
            })
          : this.translate.instant('msg.qty.cartMaxQuantity', {
              qty: this.cartTotalQuantity,
            });
      showWarning(message, this.toastr, this.translate, true);
      return;
    }
    const quantity = type == SignE.plus ? item.quantity + 1 : item.quantity - 1;
    const updateDate = { quantity: quantity, gateway: this.gateway };
    await this.api
      .updateCart(item.id, updateDate)
      .then((res: any) => {
        this.updateCartItem(res);
      })
      .catch((err) => {
        showError(err, this.toastr, this.translate, false);
      })
      .finally(() => (this.shared.spinner = false));
  }

  updateCartItem(item) {
    const index = this.cartItems.findIndex((o) => o.id === item.id);
    if (index > -1) {
      this.cartItems[index] = item;
      this.cartDetails.results = this.cartItems;
      this.cartDetails.cart = item?.cart;
      this.cartDetails.amount_payable = item?.amount_payable;
      this.cartDetails.user_points = item?.user_points;
      this.cartDetails.all_voucher_cart = item?.all_voucher_cart;
      this.cartDetails.calculated_points = item?.calculated_points;
      this.cartDetails.real_money = item?.real_money;
      this.cartTotalQuantity = this.cartItems?.reduce(
        (total, item) => total + item.quantity,
        0,
      );
      this.receivedCartChanges.emit({
        cartDetails: this.cartDetails,
        cartItems: this.cartItems,
        count: item?.items_count,
      });
    }
  }
}
